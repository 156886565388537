import React, { useState } from 'react'
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';


import { images } from '../../constants'
import './Navbar.scss';

const Navbar = () => {
    const [toggle, setToggle] = useState(false)

    const container = {
        hidden: { opacity: 0 },
        show: {
          opacity: 1,
          transition: {
            staggerChildren: 0.1
          }
        }
      }
      
      const items = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
      }
 
    return (
    <nav className='app__navbar'>
        <div className='app__navbar-logo'>
            <img src={images.logo} alt='logo' />
        </div>
        <ul className='app__navbar-links'>
            {['home', 'about', 'contact', 'work', 'skills'].map((item) => (
                <li className='app__flex p-text' key={`link-${item}`}>
                   <div />
                   <a href={`#${item}`}>{item}</a> 
                </li>
            ))}
        </ul>

        <div className='app__navbar-menu'>
                <HiMenuAlt4 onClick={() => setToggle(true)}/>

                {
                    toggle && (
                        <motion.div
                        initial={{ width: 0 }} 
                        animate={{ width: 300 }}
                        >
                            <HiX onClick={() => setToggle(false)} />
                            <motion.ul
                            variants={container}
                            initial="hidden"
                            animate="show">
                                {['home', 'about', 'contact', 'work', 'skills'].map((item) => (
                                <motion.li variants={items} key={item}>
                                    <motion.a href={`#${item}`} onClick={() => setToggle(false)}>{item}</motion.a> 
                                </motion.li>
                            ))}
                            </motion.ul>
                        </motion.div>
                    )
                }
        </div>
    </nav>
  )
}

export default Navbar
