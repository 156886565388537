import React from 'react'
import { BsGithub, BsTwitter } from 'react-icons/bs';
import { SiCodewars } from 'react-icons/si';

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
          <a href="https://github.com/leveq"> <BsGithub />
          </a>    
        </div>
        <div>
          <a href="https://www.codewars.com/users/Leveq"><SiCodewars />
          </a>           
        </div>
        <div>
            <BsTwitter />
        </div>
    </div>
  )
}

export default SocialMedia